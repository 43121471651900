import React, { useEffect, useState } from "react";

import firebase from "./../utilities/firebase.js";

import { useFormik } from "formik";

import AgoraRTC from "agora-rtc-sdk-ng";
//import { initiatePublicStream } from "../../../functions/index.js";
//import stream from './stream'
//import AgoraRTC from 'agora-rtc-sdk'

const PublicStream = () => {
  //const classes = useStyles()
  /*var rtc = {
    // For the local client.
    client: null,
    // For the local audio and video tracks.
    localAudioTrack: null,
    localVideoTrack: null,
  }*/
  const [streamDetails, setStreamDetails] = useState(false);
  const [fetchingStreamDetails, setFetchingStreamDetails] = useState(true);
  //const [questionAsked, setQuestionAsked] = useState({})
  const [isReceivingStream, setIsReceivingStream] = useState(false);
  const [isFetchingStream, setIsFetchingStream] = useState(false);
  //const [agoraClient, setAgoraClient] = useState("");
  const [buttonProcessing, setButtonProcessing] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [questionStreamLoaded, setQuestionStreamLoaded] = useState(false);
  const [viewerId, setViewerId] = useState(false);
  const [streamId, setStreamId] = useState(false);
  const [endedStream, setEndedStream] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);
  const [copy, setCopy] = useState({
    header: "Your questions",
    questionInputPlaceholder:
      "Ask the host a question about the product or service",
    questionTooFewCharsError:
      "Must be 10 characters or more. The better the question, the better the answer.",
    questionRequiredError: "Required",
    noStreamersError:
      "Currently there are no active streamers. Please try again later.",
    submitQuestionButton: "Submit your question",
    submittingQuestionButton: "Submitting...",
    leaveButton: "Leave",
    closingStream: "Closing window..",
    startingStream: "Hold on, fetching stream",
    questionStatusAnswered: "Answered",
    questionStatusSkipped: "This question was skipped by the presenter",
    questionStatusSupport:
      "This is marked as a support question. Please see their support pages.",
    whoIsStreaming: "is streaming on behalf of",
  });

  useEffect(() => {
    //Gets the pathname from URL
    let pathname = window.location.pathname;

    //Extracts ID
    let streamId = pathname.split("public-stream/").pop();

    //Removes any slashes, if any
    streamId = streamId.replace(/\//g, "");

    let brandId = streamId.split("-")[0];

    const urlParams = new URLSearchParams(window.location.search);
    const viewerId = urlParams.get("viewer");

    setViewerId(viewerId);
    setStreamId(streamId);

    const language = urlParams.get("language");

    switch (language) {
      case "en":
        setCopy({
          header: "Your questions",
          questionInputPlaceholder:
            "Ask the host a question about the product or service",
          questionTooFewCharsError:
            "Must be 10 characters or more. The better the question, the better the answer.",
          questionRequiredError: "Required",
          noStreamersError:
            "Currently there are no active streamers. Please try again later.",
          submitQuestionButton: "Submit your question",
          submittingQuestionButton: "Submitting...",
          leaveButton: "Leave",
          closingStream: "Closing window..",
          startingStream: "Hold on, fetching stream",
          questionStatusAnswered: "Answered",
          questionStatusSkipped: "This question was skipped by the presenter",
          questionStatusSupport:
            "This is marked as a support question. Please see their support pages.",
          whoIsStreaming: "is streaming on behalf of",
        });
        break;
      case "no":
        setCopy({
          header: "Dine stilte spørsmål",
          questionInputPlaceholder: "Still spørsmål om produktet",
          questionTooFewCharsError:
            "Må være 10 tegn eller mer. Jo bedre spørsmålet er stilt, jo bedre svar vil du få :)",
          questionRequiredError: "Skriv inn et spørsmål",
          noStreamersError:
            "Det er for tiden ingen aktiv stream. Prøv igjen senere :)",
          submitQuestionButton: "Send inn spørsmålet",
          submittingQuestionButton: "Sender inn...",
          leaveButton: "Lukk streamen",
          closingStream: "Lukker streamen...",
          startingStream: "Vent litt, henter streamen...",
          questionStatusAnswered: "Besvart",
          questionStatusSkipped:
            "Dette spørsmålet ble hoppet over av pressentøren",
          questionStatusSupport:
            "Dette ble markert som et support spørsmål. Se deres support sider for svar",
          whoIsStreaming: "streamer på vegne av",
        });
        break;
      default:
        setCopy({
          header: "Your questions",
          questionInputPlaceholder:
            "Ask the host a question about the product or service",
          questionTooFewCharsError:
            "Must be 10 characters or more. The better the question, the better the answer.",
          questionRequiredError: "Required",
          noStreamersError:
            "Currently there are no active streamers. Please try again later.",
          submitQuestionButton: "Submit your question",
          submittingQuestionButton: "Submitting...",
          leaveButton: "Leave",
          closingStream: "Closing window..",
          startingStream: "Hold on, fetching stream",
          questionStatusAnswered: "Answered",
          questionStatusSkipped: "This question was skipped by the presenter",
          questionStatusSupport:
            "This is marked as a support question. Please see their support pages.",
          whoIsStreaming: "is streaming on behalf of",
        });
    }

    initiatePublicStream(streamId, viewerId, brandId);

    //getStreamInfoFromStreamId(streamId);
    //addViewerToStream(viewerId, streamId, brandId);
  }, []);

  const initiatePublicStream = (streamId, viewerId, brandId) => {
    var initiatePublicStream = firebase
      .functions()
      .httpsCallable("initiatePublicStream");

    initiatePublicStream({
      streamId: streamId,
      viewerId: viewerId,
      brandId: brandId,
    }).then((result) => {
      if (result.data) {
        setStreamDetails(result.data);
        setFetchingStreamDetails(false);
      } else {
        setStreamDetails(false);
        setFetchingStreamDetails(false);
      }
    });
  };

  const getStreamInfoFromStreamId = (streamId) => {
    var streamInfo = firebase
      .functions()
      .httpsCallable("getStreamInfoFromStreamId");

    streamInfo(streamId).then((result) => {
      if (result.data) {
        setStreamDetails(result.data);
        setFetchingStreamDetails(false);
      } else {
        setStreamDetails(false);
        setFetchingStreamDetails(false);
      }
    });
  };

  const addViewerToStream = (viewerId, streamId, brandId) => {
    var viewerToStream = firebase
      .functions()
      .httpsCallable("addViewerToStream");

    viewerToStream({
      viewerId: viewerId,
      streamId: streamId,
      brandId: brandId,
    }).then((result) => {});
  };

  const leaveStream = () => {
    setEndedStream(true);
    setIsReceivingStream(false);
    setIsFetchingStream(false);
    setStreamDetails(false);

    var viewerFromStream = firebase
      .functions()
      .httpsCallable("removeViewerFromStream");

    viewerFromStream({ viewerId: viewerId, streamDocumentId: streamId }).then(
      (result) => {
        window.close();
      }
    );
  };

  const deleteQuestion = (questionDocumentId) => {
    var deleteQuestion = firebase.functions().httpsCallable("deleteQuestion");

    deleteQuestion({
      streamId: streamId,
      questionId: questionDocumentId,
    }).then((result) => {});
  };

  if (streamDetails && !isFetchingStream && !isReceivingStream) {
    setIsFetchingStream(true);
    joinStream();
  }

  async function joinStream() {
    /*let options = {
      appId: "c25228728923422ea4b86236cfed238b",
      channel: "test",
      token:
        "006c25228728923422ea4b86236cfed238bIADtbJpmQzHFu3y5TbDUYBWdKbQtj8nYCvjCUpaSv6tvVAx+f9gAAAAAEAAoIaFGLbryYAEAAQAsuvJg",
      role: "audience",
      uid: "",
    };*/

    let options = {
      appId: streamDetails.streamAppId,
      channel: streamDetails.streamId,
      token: streamDetails.streamToken,
      role: "audience",
      uid: streamDetails.viewerUserId,
    };

    var client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });

    //setAgoraClient(client);

    client.setClientRole(options.role);

    //client.on('user-published', handleUserPublished)
    //client.on('user-unpublished', handleUserUnpublished)

    client.on("user-published", async (user, mediaType) => {
      // Subscribe to a remote user.
      await client.subscribe(user, mediaType);

      // If the subscribed track is video.
      if (mediaType === "video") {
        // Get `RemoteVideoTrack` in the `user` object.
        const remoteVideoTrack = user.videoTrack;
        const playerContainer = document.getElementById("video-container");

        remoteVideoTrack.play(playerContainer);
        setIsReceivingStream(true);
        setIsFetchingStream(false);
        //listenForQuestions()
      }

      // If the subscribed track is audio.
      if (mediaType === "audio") {
        // Get `RemoteAudioTrack` in the `user` object.
        const remoteAudioTrack = user.audioTrack;
        // Play the audio track. No need to pass any DOM element.
        remoteAudioTrack.play();
      }
    });
    client.on("user-unpublished", (user) => {
      leaveStream();
    });

    await client.join(
      options.appId,
      options.channel,
      options.token || null,
      options.uid
    );
  }

  if (viewerId && !questionStreamLoaded) {
    setQuestionStreamLoaded(true);
    //Listen for changes in the question sub collection
    let tempQuestionsArray = [];
    firebase
      .firestore()
      .collection("questions")
      .where("streamId", "==", streamId)
      .where("viewerId", "==", viewerId)
      .orderBy("createdAt")
      .onSnapshot(function (snapshot) {
        snapshot.forEach(function (doc) {
          /*snapshot.docChanges().forEach(function (change) {
          if (change.type === 'added') {
            console.log('New city: ', change.doc.data())
          }
          if (change.type === 'modified') {
            console.log('Modified city: ', change.doc.data())
          }
          if (change.type === 'removed') {
            console.log('Removed city: ', change.doc.data())
          }
        })*/

          let newObject = doc.data();
          newObject.documentId = doc.id;

          tempQuestionsArray.push(newObject);
        });

        setQuestionList(tempQuestionsArray);

        tempQuestionsArray = [];
      });
  }

  const validate = (values) => {
    const errors = {};
    if (!values.question) {
      errors.question = copy.questionRequiredError;
    } else if (values.question.length < 10) {
      errors.question = copy.questionTooFewCharsError;
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      question: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setButtonProcessing(true);
      var addQuestion = firebase.functions().httpsCallable("addQuestion");

      addQuestion({
        question: values.question,
        brandId: streamDetails.brandId,
        streamId: streamId,
        viewerId: viewerId,
      }).then((result) => {
        setButtonProcessing(false);
        if (result.data.status === "error") {
          setErrorMessage(result.data.message);
        }

        //setQuestionAsked(result.data)
      });

      resetForm({});
    },
  });

  return endedStream ? (
    <div className="flex justify-center h-screen w-full">
      <p className="self-center">{copy.closingStream}</p>
    </div>
  ) : fetchingStreamDetails ? (
    <div className="flex justify-center h-screen w-full">
      <p className="self-center">{copy.startingStream}</p>
    </div>
  ) : !streamDetails ? (
    <div className="flex justify-center h-screen w-full">
      <p className="self-center">{copy.noStreamersError}</p>
    </div>
  ) : (
    <div className="flex flex-col lg:flex-row h-screen">
      <div className="flex items-center justify-center flex-col flex-grow lg:h-full bg-gray-900">
        <p className="absolute top-10 left-10 font-normal text-white lg:text-base hidden lg:block">
          <span className="font-bold">{streamDetails.streamerFirstName}</span>{" "}
          {copy.whoIsStreaming}{" "}
          <span className="font-bold">{streamDetails.brandName}</span>
        </p>
        <div className="relative w-full lg:w-10/12 h-0 pb-555 lg:pb-455">
          <div
            id="video-container"
            className="h-full w-full flex justify-center items-end transform scale-x-n100 top-0 left-0 absolute"
          ></div>
        </div>
      </div>
      <div className="flex flex-col lg:justify-between h-full w-full lg:w-80 px-8 pb-8">
        <div className="mt-5 mb-3 lg:hidden">
          <p className="font-normal text-gray-900 lg:text-base">
            <span className="font-bold">{streamDetails.streamerFirstName}</span>{" "}
            {copy.whoIsStreaming}{" "}
            <span className="font-bold">{streamDetails.brandName}</span>
          </p>
        </div>
        <div className="flex-none lg:h-4/6 overflow-scroll">
          <div>
            <h3 className="mt-2 lg:mt-10">{copy.header}</h3>
            <div className="flow-root mt-4">
              <ul className="-my-5 divide-y divide-gray-200">
                {questionList.map((listItem, i) => {
                  return (
                    <li className="py-5" key={i}>
                      <div className="relative">
                        <p className="font-semibold text-gray-900">
                          {/* Extend touch target to entire panel */}
                          {new Date(
                            listItem.createdAt * 1000
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                          })}
                        </p>
                        <p className="text-base mb-3">{listItem.question}</p>
                        <div className="flex justify-between mt-1">
                          {listItem.status === "answered" ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                              {copy.questionStatusAnswered}
                            </span>
                          ) : listItem.status === "skipped" ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 text-sm font-medium bg-yellow-100 text-yellow-800">
                              {copy.questionStatusSkipped}
                            </span>
                          ) : listItem.status === "support" ? (
                            streamDetails.supportsiteURL ? (
                              <span className="px-2.5 py-1 text-sm font-medium bg-yellow-100 text-yellow-800">
                                This is marked as a support question. See their
                                support pages{" "}
                                <a
                                  className="underline"
                                  href={streamDetails.supportsiteURL}
                                  target="_blank"
                                >
                                  here
                                </a>
                                .
                              </span>
                            ) : (
                              <span className="inline-flex items-center px-2.5 py-0.5 text-xs font-medium bg-yellow-100 text-yellow-800">
                                {copy.questionStatusSupport}
                              </span>
                            )
                          ) : (
                            <button
                              type="button"
                              onClick={() =>
                                deleteQuestion(listItem.documentId)
                              }
                              className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 z-10"
                            >
                              <svg
                                className="h-4 w-4"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                                />
                              </svg>
                            </button>
                          )}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="flex-none mt-5 lg:mt-0">
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div className="mt-1">
                <textarea
                  type="text"
                  name="question"
                  id="question"
                  rows={4}
                  //className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md resize-none"
                  placeholder={copy.questionInputPlaceholder}
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.question}
                  className={`${
                    formik.errors.question &&
                    formik.touched.question &&
                    "border-red-800"
                  } "border-gray-700 border-2 focus:ring-indigo-500 focus:border-indigo-500 mt-1 p-2 block w-full sm:text-base rounded-md resize-none placeholder-gray-400`}
                />
                {formik.errors.question && formik.touched.question && (
                  <span className="inline-flex items-center px-2 py-0.5 mt-2 rounded text-xs font-medium bg-red-100 text-red-800">
                    {formik.errors.question}
                  </span>
                )}
              </div>
              <button
                type="submit"
                className="disabled:opacity-50 inline-flex justify-center py-2 px-4 w-full my-4 primary-button"
                disabled={buttonProcessing}
              >
                {buttonProcessing ? (
                  <>
                    <svg
                      className="animate-spin h-5 w-5 mr-3 flex justify-center align-middle text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>{" "}
                    {copy.submittingQuestionButton}
                  </>
                ) : (
                  copy.submitQuestionButton
                )}
              </button>
              {errorMessage && (
                <span className="inline-flex items-center px-2.5 py-0.5 text-xs font-medium bg-red-100 text-red-800">
                  {errorMessage}
                </span>
              )}
            </form>
          </div>
          <button
            onClick={() => leaveStream()}
            //type="submit"
            //className="inline-flex justify-center px-4 py-2 mb-8 w-full border border-transparent text-sm font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            className="inline-flex justify-center px-4 py-2 mt-8 mb-4 w-full border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

            //className="inline-flex justify-center py-2 px-4 w-full my-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {copy.leaveButton}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PublicStream;
